import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import { GET_PLAYERS } from '../../redux/actions/actionTypes';
import PlayerCard from '../Player/PlayerCard';

class PlayerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      offset: 0,
      season: '2024',
    };
    this.changeSearch = this.changeSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
    this.changeSeason = this.changeSeason.bind(this);
    this.search = this.search.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  changeSearch(e) {
    e.preventDefault();
    this.setState({ searchTerm: e.target.value });
  }

  changeSeason(season) {
    this.setState({ season: season }, () => {
      this.search();
    });
  }

  submitSearch(e) {
    e.preventDefault();
    this.search();
  }

  search() {
    if (this.state.searchTerm.length === 0) {
      this.props.getPlayers({
        league_org: this.props.league_org,
        season: this.state.season,
      });
    } else {
      this.props.getPlayers({
        league_org: this.props.league_org,
        search: this.state.searchTerm,
        season: this.state.season,
      });
    }
  }

  loadMore(e) {
    e.preventDefault();
    this.props.getPlayers({
      league_org: this.props.league_org,
      offset: this.props.players.length,
      season: this.state.season,
    });
  }

  render() {
    let currentYear = new Date().getFullYear();
    let currentSeason = currentYear.toString();
    let pastSeason = (currentYear - 1).toString();
    let players = this.props.players
      .sort((p1, p2) => {
        return p2.scores[0].points - p1.scores[0].points;
      })
      .map((player, i) => {
        return <PlayerCard key={i} player={player} index={i}/>;
      });

    return (
      <div className="w-full sm:w-2/4 sm:px-4 mb-10">
        <div className="w-full bg-darker-green bg-opacity-50 p-4 border-b-4 border-green-100 mb-2">
          <div className="text-gray-700 text-center">
            <h1 className="text-white font-bold text-3xl mb-5">
              TOP{' '}
              {this.props.league_org === 'ATP' ? (
                <span className="text-black">{this.props.league_org}</span>
              ) : (
                <span className="text-purple-700">{this.props.league_org}</span>
              )}{' '}
              PLAYERS
            </h1>
            <div className="w-2/3 m-auto">
              <button
                onClick={() => this.changeSeason(currentSeason)}
                className={`w-1/2 hover:bg-dark-green text-white text-sm border-2 text-white py-1 rounded ${
                  this.state.season === currentSeason
                    ? 'bg-dark-green underline font-bold'
                    : 'bg-darker-green'
                }`}
              >
                {currentSeason}
              </button>
              <button
                onClick={() => this.changeSeason(pastSeason)}
                className={`w-1/2 hover:bg-dark-green text-white text-sm border-2 text-white py-1 px-2 rounded ${
                  this.state.season === pastSeason
                    ? 'bg-dark-green underline font-bold'
                    : 'bg-darker-green'
                }`}
              >
                {pastSeason}
              </button>
            </div>
            <form onSubmit={this.submitSearch}>
              <div className="flex items-center justify-center my-6">
                <div className="border text-white border-white rounded-full rounded overflow-hidden flex">
                  <input
                    type="text"
                    className="px-4 py-2 bg-darker-green bg-opacity-50 focus:outline-none"
                    placeholder="Search..."
                    onChange={this.changeSearch}
                    value={this.state.searchTerm}
                  />
                  <button className="flex items-center justify-center px-4 bg-darker-green opacity-50" type="submit">
                    <svg
                      className="h-4 w-4 text-white"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {players.length > 0 ? players : <div className="rounded-full bg-white text-center"><h3>No Players Found</h3></div>}
        {players.length >= 20 && !this.state.searchTerm && (
          <button
            onClick={this.loadMore}
            className="w-full bg-darker-green hover:bg-dark-green text-white text-sm border-2 text-white py-1 px-2 rounded"
          >
            Load More
          </button>
        )}
      </div>
    );
  }
}

class PlayerLists extends Component {
  componentDidMount() {
    this.props.getPlayers({
      league_org: 'ATP',
      limit: 20,
      season: '2024',
    });
    this.props.getPlayers({
      league_org: 'WTA',
      limit: 20,
      season: '2024',
    });
  }

  render() {
    var AtpPlayers, WtaPlayers;

    AtpPlayers = (
      <PlayerList
        league={this.props.league}
        league_org="ATP"
        players={this.props.atp_players}
        getPlayers={this.props.getPlayers}
      />
    );

    WtaPlayers = (
      <PlayerList
        league={this.props.league}
        league_org="WTA"
        players={this.props.wta_players}
        getPlayers={this.props.getPlayers}
      />
    );

    return (
      <div className="flex flex-wrap justify-center">
        {AtpPlayers}
        {WtaPlayers}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    players: state.players.players,
    atp_players: state.players.atp_players,
    wta_players: state.players.wta_players,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPlayers: (filters) => dispatch({ type: GET_PLAYERS, filters }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerLists);
